@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;900&display=swap');
@import url('rc-time-picker/assets/index.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .input-mycia {
        @apply outline-none w-full px-4 py-2 rounded-lg font-light text-lg placeholder-mycia-dimGray bg-white text-black;
    }

    .input-mycia:valid {
        @apply bg-mycia-light;
    }

    .input-mycia-secondary {
        @apply input-mycia bg-mycia-light;
    }
    
    .button-mycia {
        @apply max-w-full py-2 px-4 shadow shadow-mycia-orangeRed rounded-lg font-semibold text-lg uppercase select-none bg-mycia-orangeRed text-white text-center cursor-pointer;
    }

    .button-mycia-white {
        @apply max-w-full cursor-pointer select-none rounded-lg border-none bg-white py-3 px-4 text-center font-semibold uppercase text-black shadow-mycia;
    }

    .icon-mycia {
        @apply w-4 h-4; 
    }
    
    .logo {
        @apply h-auto w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6;
    }

    .icon-mycia {
        @apply h-4 w-4;
    }

    .select-mycia:focus + label > div > span {
        @apply bg-mycia-medium;
    }

    .select-mycia:checked + label > div > span {
        @apply bg-mycia-red;
    }

    .select-mycia-div {
        @apply mr-2 flex items-center justify-center rounded-full border-2 border-black p-0.5;
    }

    .select-mycia-span {
        @apply inline-block min-h-[12px] min-w-[12px] rounded-full bg-white;
        @apply transition-colors duration-200;
    }

    .select-mycia-label {
        @apply flex cursor-pointer items-start text-sm;
    }

    .select-mycia-shadow {
        @apply rounded-xl border-2 border-transparent shadow-mycia;
    }

    .select-mycia-shadow-none {
        @apply rounded-xl border-2 border-mycia-medium shadow-none;
    }
    
    input[type="checkbox"]:checked + div {
        @apply border-mycia-dimGray;
    }
    
    input[type="checkbox"]:checked + div svg {
        @apply block;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        @apply bg-none;
    }

    .select-mycia:focus + label > div > span {
        @apply bg-mycia-medium;
    }
    
    .select-mycia:checked + label > div > span {
        @apply bg-mycia-red;
    }

    .select-mycia-div {
        @apply flex mr-2 rounded-full justify-center items-center p-0.5 border-2 border-black;
    }

    .select-mycia-span {
        @apply inline-block min-h-[12px] min-w-[12px] rounded-full bg-white;
        @apply transition-colors duration-200;
    }

    .select-mycia-label {
        @apply flex cursor-pointer items-start text-sm;
    }

    .select-mycia-shadow {
        @apply shadow-mycia border-transparent;
    }

    .select-mycia-shadow-none {
        @apply shadow-none border-mycia-medium;
    }

    .toggle-mycia {
        @apply shrink-0 ml-4 h-5 w-9 rounded-full bg-gray-200 flex after:self-center after:mx-0.5 after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-mycia-orangeRed peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:bg-mycia-titaniumGray;
    }

    .column-item {
        @apply w-[calc(50%-1rem)] p-2.5 sm:w-[calc(33.3%-1rem)] md:w-[calc(20%-1.2rem)] lg:max-w-[12.5rem] cursor-pointer select-none
    }

    .visible-print-div .visible-item {
        @apply block;
    }

    .visible-item {
        @apply hidden;
    }

    .mycia-time-picker-input > .rc-time-picker-input {
        @apply input-mycia-secondary py-5 border-none max-w-[10rem]
    }

    .mycia-time-picker-input > .rc-time-picker-clear {
        @apply top-2
    }
}
